
import Img1 from '../Images/final.png';
import Img2 from '../Images/slider/1.png'
import Img3 from '../Images/slider/2.png'
import Img4 from '../Images/slider/3.png'
import Img5 from '../Images/fnl_abt.jpg'
import Img6 from '../Images/bgshape1.png'
import Img7 from '../Images/bgshape2.png'
import Img8 from '../Images/testimonialimage/1.jpg'



function Body() {
    return (
        <>
            {/* <div className="preloader" id="preloader">
                <div className="preloader-inner">
                    <div className="cube-wrapper">
                        <div className="cube-folding">
                            <span className="leaf1"></span>
                            <span className="leaf2"></span>
                            <span className="leaf3"></span>
                            <span className="leaf4"></span>
                        </div>
                        <span className="loading" data-name="Loading">Loading</span>
                    </div>
                </div>
            </div> */}

            <header className="navigation stiky-nav">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 p-0">
                            <nav className="navbar navbar-expand-lg navbar-light">
                                <a className="navbar-brand" href="index.php">
                                    <img src={Img1} alt="description gygf" />
                                </a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainmenu"
                                    aria-controls="mainmenu" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="mainmenu">
                                    <ul className="navbar-nav ml-auto">
                                        <li className="nav-item active">
                                            <a className="nav-link home1" href="#home">Home</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link about1" href="#about">Who We Are</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#services">Services</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#why_us">Why Us</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#contact">Contact</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            <div id="home" style={{ marginTop: '83.07px' }}>
                <div class="bd-example">
                    <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
                        <ol class="carousel-indicators">
                            <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
                            <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
                            <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
                        </ol>
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img src={Img2} class="d-block w-100" alt="...3" />
                                
                            </div>
                            <div class="carousel-item">
                                <img src={Img3} class="d-block w-100" alt="...2" />
                                
                            </div>
                            <div class="carousel-item">
                                <img src={Img4} class="d-block w-100" alt="...1" />
                                
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
            <section className="about" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <img src={Img5} alt="img of dec 5" />
                        </div>
                        <div className="col-xl-6 d-flex align-self-center">
                            <div className="info">
                                <h2 className="info-title"><span>Who We Are</span></h2>
                                <p>We at Sky Exim, provide Market Reports of Active Pharmaceutical Ingredients (API’s), Intermediates and Pharmaceutical Formulations.These Export-Import reports are results of rigorous market research  through which you will be able to make better Marketing and Sourcing decisions.
                                </p>
                                <p>
                                    We have detailed reports about all aspects of this industry and hence we can provide you the market reports.These Export-Import reports of various Pharmaceutical products are results of rigorous market research.
                                </p>
                                <p>
                                    We are only Pharma Focused and hence our expertise lies in the Industry, we have the knowledge and analysing capability that will help you make the best use of these reports.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pricing" id="services" style={{ padding: '110px 0px 56px' }}>
                <div className="background"></div>
                <img className="shape1" src={Img6} alt="description of screen6" />
                <img className="shape2" src={Img7} alt="description of screen7" />
                <div className="container font_clr_cng">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-8">
                            <div className="section-title extra">
                                <h2 className="title clr_fnt">Our Services</h2>
                                <p className="clr_fnt">At Sky Exim, we work on accurate and complete processing of data.Our services are best suited for companies and clients that are in export-import, freight (movers) and for trade consultants.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 pricing-tab">
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-monthly" role="tabpanel" aria-labelledby="pills-monthly-tab">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-4 col-md-4 top_b_space">
                                            <div className="services_heigh price-box basic service_box">
                                                <div className="price-title">
                                                    <h3 className="heading-title">Reports of API's</h3>

                                                </div>
                                                <div className="price-rate">
                                                    <div className="center-align-content box1">
                                                        <div className="inner-box">
                                                            <div className="icon">
                                                                <i className="icn flaticon-layers"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="service-feature">
                                                    <ul className="service-feature-list">
                                                        <li>
                                                            <p>The Active Pharmaceutical Ingredient (API) is the part of any drug that produces the intended effects. Some drugs, such as combination therapies, have multiple active ingredients to treat different symptoms or act in different ways.We provide the reports for these API’s that are exported and imported across different geographical locations.</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 top_b_space">
                                            <div className="services_heigh price-box standard service_box">
                                                <div className="price-title">
                                                    <h3 className="heading-title">Reports of Intermediates</h3>

                                                </div>
                                                <div className="price-rate">
                                                    <div className="center-align-content">
                                                        <div className="center-align-content box1">
                                                            <div className="inner-box">
                                                                <div className="icon">
                                                                    <i className="icn flaticon-layers"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="service-feature">
                                                    <ul className="service-feature-list">
                                                        <li>
                                                            <p>Intermediates are raw materials that need to be processed before they can be sold to consumers. These intermediate is the product of each step, intermediates are produced by pharmaceutical units which are then supplied to medicine-producing companies.Our reports of these intermediates make it easier for businesses to get in touch with direct suppliers.</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 top_b_space">
                                            <div className="services_heigh price-box standard service_box">
                                                <div className="price-title">
                                                    <h3 className="heading-title">Reports of Formulations</h3>

                                                </div>
                                                <div className="price-rate">
                                                    <div className="center-align-content">
                                                        <div className="center-align-content box1">
                                                            <div className="inner-box">
                                                                <div className="icon">
                                                                    <i className="icn flaticon-layers"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="service-feature">
                                                    <ul className="service-feature-list">
                                                        <li>
                                                            <p>A formulation is a mixture of chemicals that do not chemically react. This mixture of chemicals is designed to produce a final product with desirable characteristics, such as sunscreen. Formulations differ from pure substances, a substance that is comprised of only one type of chemical. Our formulations reports will help you to find new buyers and suppliers.</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="service" id="why_us">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-12">
                            <div className="section-title">
                                <h2 className="info-title">Why us</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 d-flex align-self-center">
                            <p>The Pharmaceutical Industry is one of biggest growing industries with multiple companies entering into the market every month, to cope and stay ahead of your competitors it’s important to keep track of the movements and developments in the market.
                                <br />
                                <br />
                                Our objective is to help clients make better, informed decisions by knowing important information about buyers and suppliers of these products through our market research and extensive data analysis.Hence we can help you in many ways to grow your business and make key business decisions, get in touch with us for more information. we work towards maximizing your business by bringing market-related information to you in the form of export-import reports.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonial-area" id="testimonial-area">
                <div className="background"></div>
                <img className="shape1" src={Img6} alt="description of screen8" />
                <img className="shape2" src={Img7} alt="description of screen9" />
                <div className="container font_clr_cng">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-8">
                            <div className="section-title extra">
                                <h2 className="title clr_fnt">Our Clients Say </h2>
                                <p className="clr_fnt">Our growth is in proportion with our client's growth. So we help them to achieve their goals through our analysis.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9">
                            <div className="testimonial-slider">
                                <div className="item">
                                    <div className="client">
                                        <div className="client-image">
                                            <img src={Img8} className="img-fluid" alt="description of screen10" />
                                        </div>
                                        <p className="client-say">Sky Exim 's professional team have helped me to movement about my products in market.</p>
                                        <h4 className="client-name">
                                            <span>
                                                Mr. Masih
                                            </span>
                                        </h4>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="client">
                                        <div className="client-image">
                                            <img src={Img8} className="img-fluid" alt="description of screen11" />
                                        </div>
                                        <p className="client-say">Thank you Sky Exim.This company helped me to understand current market scenario.This guys are amazing in their work.</p>
                                        <h4 className="client-name">
                                            <span>
                                                Mr. Wang liho
                                            </span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact" id="contact">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-8">
                            <div className="section-title">
                                <h2 className="title">Get a Free Quote</h2>
                                <p>You will hear from us within 24 hours! A Non-disclosure agreement is availeble for a Confidantial consultation.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4">
                            <ul className="info-contact">
                                <li>
                                    <div className="info-box box1">
                                        <div className="left">
                                            <div className="icon">
                                                <i className="fas fa-phone"></i>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="content">
                                                <p>+91 96244 65190</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-box box2">
                                        <div className="left">
                                            <div className="icon">
                                                <i className="fas fa-envelope"></i>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="content">
                                                <p>skyeximanalyst@yahoo.com</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xl-8">
                            <div className="contact-form-wrapper">
                                <form action="" method="POST">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input type="text" className="input-field borderd" name="name" id="name" placeholder="Your Name"
                                                required />
                                        </div>
                                        <div className="col-md-12">
                                            <input type="email" className="input-field borderd" name="email" id="email" placeholder="Your Email"
                                                required />
                                        </div>
                                        <div className="col-12">
                                            <textarea className="input-field borderd textarea" rows="3" name="message" id="message" placeholder="Write your message here"
                                                required></textarea>
                                        </div>
                                        <div className="col-12">
                                            <input type="submit" name="submit" className="mybtn1" value="Send Message" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="footer" id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-2">
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="footer-info-area text-center">
                                <div className="footer-logo">
                                    <span className="logo-link">
                                        <img src={Img1} alt="description of screen12" />
                                    </span>
                                </div>
                                <div className="text">
                                    <p>
                                    </p>
                                </div>
                            </div>
                            <div className="fotter-social-links">
                                <ul style={{ textAlign: 'center' }}>
                                    <li>
                                        <a href="https://wa.me/+919624465190" rel="noreferrer" target="_parent" className="whatsapp" style={{ backgroundColor: '#008000' }}>
                                            <i className="fab fa-2x fa-whatsapp"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-2">
                            <div className="footer-widget use-full-link-widget">
                                <h4 className="title">
                                    RELATED LINKS
                                </h4>
                                <ul className="link-list">
                                    <li>
                                        <a href="#home">
                                            <i className="fas fa-angle-double-right"></i>Home
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#about">
                                            <i className="fas fa-angle-double-right"></i>About Us
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#services">
                                            <i className="fas fa-angle-double-right"></i>Our Services
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#why_us">
                                            <i className="fas fa-angle-double-right"></i>Why Us
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#contact">
                                            <i className="fas fa-angle-double-right"></i>Contact Us
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-2">
                        </div>
                    </div>
                </div>
                <div className="copy-bg text-center">
                    <p>
                        Copyright © 2021. All Rights Reserved.
                    </p>
                </div>
            </footer>
            <div className="bottomtotop">
                <i className="fa fa-chevron-right"></i>
            </div>
        </>

    );
}

export default Body;