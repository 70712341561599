import Body from './Components/Body';


function App() {
  return (
    <>
    
    <Body/>
    
    </>
  );
}

export default App;
